<template>
  <div class="productslist">
    <!-- Indicador de Carregamento -->
    <span id="loading" v-if="this.loading == true">
      <Loading />
    </span>

    <!-- Título dos Relatórios -->
    <h2>Relatórios</h2>
    <hr />

    <!-- Secção de Relatório de Vendas por Marca -->
    <div class="report-section">
      <h3>Relatório de Vendas por Marca</h3>

      <!-- Secção de Input de Datas -->
      <div class="searchbar">
        <div class="input-group">
          <label for="start_date">Data de Início</label>
          <input type="date" v-model="start_date" id="start_date" name="start_date" required />
        </div>
        <div class="input-group">
          <label for="end_date">Data de Fim</label>
          <input type="date" v-model="end_date" id="end_date" name="end_date" required />
        </div>
        <button class="btn-report" @click="getBrandsReport">Gerar Relatório</button>
      </div>
    </div>

    <!-- Secção de Relatório de Vendas por Produto e País -->
    <div class="report-section">
      <h3>Relatório de Vendas por Produto e País</h3>

      <!-- Secção de Input de Produto e País -->
      <div class="input-group">
        <label for="product">Produto</label>
        <input type="text" v-model="product" id="product" name="product" placeholder="Insere o SKU do produto" />
      </div>
      <div class="input-group">
        <label for="country">País</label>
        <select v-model="country" id="country" name="country">
          <option v-for="country in countries" :key="country.code" :value="country.code">{{ country.name }}</option>
        </select>
      </div>
      <button class="btn-report" @click="getSalesReportProductsCountry">Gerar Relatório</button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Loading from '@/components/Loading.vue';
export default {
  components: {
    Loading,
  },
  data: function () {
    return {
      loading: false,
      countries: [],

      //Sales Report Products Country
      product: '',
      country: '',

      //Brands Report
      start_date: '',
      end_date: '',
    };
  },
  methods: {
    //Sales Report Products Country
    getSalesReportProductsCountry() {
      this.loading = true;
      this.$store
        .dispatch('getSalesByCountryProduct', {
          product: this.product,
          country: this.country,
        })
        .then(() => {
          this.loading = false;
        });
    },

    //Brands Report
    getBrandsReport() {
      this.loading = true;
      this.$store
        .dispatch('getBrandSales', {
          start_date: this.start_date,
          end_date: this.end_date,
        })
        .then(() => {
          this.loading = false;
        });
    },

    //Countries
    async allCountries() {
      try {
        await this.$store.dispatch('fetchAllCountries');
        this.countries = this.getAllCountries;
        console.log(this.countries);
      } catch (err) {
        alert(err);
      }
    },
  },
  computed: {
    ...mapGetters(['getAllCountries']),
  },
  async created() {
    await this.allCountries();
  },
};
</script>

<style scoped>
.productslist {
  text-align: center;
  padding: 20px;
  font-family: 'Arial', sans-serif;
}

/* General Styling */
.report-section {
  margin: 20px 0;
}

h2 {
  margin-top: 20px;
}

.searchbar {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 10px;
  background-color: #f8f9fa;
  border-radius: 5px;
}

.input-group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0 10px;
}

input,
select {
  padding: 8px;
  margin-top: 5px;
  border-radius: 4px;
  border: 1px solid #ccc;
  width: 100%;
  box-sizing: border-box;
}

input:focus,
select:focus {
  outline: none;
  border-color: #007bff;
}

label {
  font-size: 14px;
  font-weight: bold;
  color: #333;
}

/* Button Styling */
.btn-report {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease;
}

.btn-report:hover {
  background-color: #0056b3;
}

/* Loading */
#loading {
  margin-top: 20px;
}
</style>
